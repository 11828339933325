body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-neutral-200
}

html {
  @apply bg-white
}


@font-face {
  font-family: CormorantGaramond;
  font-weight: bold;
  src: url(fonts/CormorantGaramond-Bold.ttf);
}

@font-face {
  font-family: Mak;
  font-weight: medium;
  src: url(fonts/MAK.otf);
}

@font-face {
  font-family: CormorantGaramond;
  font-weight: medium;
  src: url(fonts/CormorantGaramond-Medium.ttf);
}

@font-face {
  font-family: Quicksand;
  font-weight: 100 900;
  src: url(fonts/Quicksand-VariableFont_wght.ttf);
}

@font-face {
  font-family: Raleway;
  font-weight: 100 900;
  src: url(fonts/Raleway-VariableFont_wght.ttf);
}

input {
  @apply focus:outline-none
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  color: #888
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 

@keyframes ht {
  100% { height: 0px }
}

.blinking-cursor {
  color: #2E3D48;
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}



@keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-moz-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-webkit-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-ms-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-o-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: black;
  }
}